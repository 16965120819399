import React, { useEffect } from 'react';
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import './Footer.css'

import footerLogo from '../Assets/logo-text.svg'
import locationIcon from '../Assets/footerImages/location.svg'
import call from '../Assets/footerImages/call.svg'
import mail from '../Assets/footerImages/mail.svg'
import facebook from '../Assets/footerImages/facebook.svg'
import instagram from '../Assets/footerImages/instagram.svg'
import linkedin from '../Assets/footerImages/linkedin.svg'
import whatsapp from '../Assets/footerImages/whatsapp.svg'
import CardVectorOne from "../Assets/cardIcons/card-vector (1).png";
import CardVectorTwo from "../Assets/cardIcons/card-vector (2).png";
import CardVectorThree from "../Assets/cardIcons/card-vector (3).png";

const Footer = () => {
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional for smooth scrolling
        });
    };

    const clickHandler = () => {
        scrollToTop();
        navigate("/contact")
    }

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <header>
            <div className="card-contact-container container">
                <h2 className='section-title'>
                    Остались вопросы или нужна консультация?
                </h2>
                <br/>
                <div className="contact-options">
                    <div className="contact-option">
                        <img src={CardVectorOne} alt='card-vector' className="contact-icon"/>
                        <div>
                            <h2 className='contact-option-title'>Часто задаваемые вопросы</h2>
                            <p className='contact-option-description'>и ответы на них</p>
                        </div>
                    </div>

                    <div className="contact-option"
                         onClick={() => clickHandler() }
                    >
                        <img src={CardVectorTwo} alt='card-vector' className="contact-icon"/>
                        <div>
                            <h2 className='contact-option-title'>Связаться с банком</h2>
                            <p className='contact-option-description'>Связь со службой поддержки</p>
                        </div>
                    </div>

                    <div className="contact-option"
                         onClick={() => clickHandler() }
                    >
                        <img src={CardVectorThree} alt='card-vector' className="contact-icon"/>
                        <div>
                            <h2 className='contact-option-title'>Отправить обращение</h2>
                            <p className='contact-option-description'>нам важно ваше мнение</p>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer_wrapper">
                <div className='footer_container container'>
                    <div className="links_column">
                        <img src={footerLogo} alt={""} className='footer-logo-img' width={144}/>
                        <span className="copyright">Номер Лицензии: B2022078</span>
                        <span className="copyright">Дата выдачи: 02.05.2022</span>
                        <span className="copyright">Срок действия: Бессрочная</span>
                        <span className="copyright">Орган, выдавший лицензию:  <br/>
                            Mwali International Services Authority, <br/> Fomboni, Union of Comoros</span>
                        <span className="copyright">
                            все права защищены © 2024
                            <br/>
                            Международная СберКасса
                        </span>
                    </div>

                    <div className="links_column">
                        <span className="links_title">Общее</span>
                        <div className="mobile_row">
                            <Link className="link" to="/" onClick={scrollToTop}>Главная</Link>
                            <Link className="link" to="/aboutBank" onClick={scrollToTop}>О банке</Link>
                            <Link className="link" to="/services" onClick={scrollToTop}>Тарифы банка</Link>
                            <Link className="link" to="/contact" onClick={scrollToTop}>Реквизиты</Link>
                        </div>
                    </div>

                    <div className="links_column">
                        <span className="links_title" onClick={scrollToTop}>Услуги</span>
                        <Link className="link" to="/corporate" onClick={scrollToTop}>Корпоративным клиентам</Link>
                        <Link className="link" to="/crypto" onClick={scrollToTop}>Операции с криптовалютой</Link>
                        <NavLink className="link" to="/other#cashing-checks" >Обналичивание банковских чеков</NavLink>
                        <NavLink className="link" to="/other#international-acquiring" onClick={scrollToTop}>Международный эквайринг</NavLink>
                        <NavLink className="link" to="/other#operations-metals" onClick={scrollToTop}>Операции с драгоценными металлами</NavLink>
                        <NavLink className="link" to="/other#sberkassa-fx" onClick={scrollToTop}>Международная СберКасса FX</NavLink>
                    </div>

                    <div className="links_column">

                        <div className='footer-address'>
                            <span className="links_title">Контакты</span>
                            <div className="link">
                                <div>
                                    <img src={locationIcon} style={{marginRight: '10px'}} alt={""} width={24}
                                         height={24}/>
                                </div>
                                <div>
                                    Glandore Office Building, Fitzwilliam Hall, 26 Fitzwilliam <br/>
                                    Pl, Dublin 2, D02 T292, Ireland
                                    <div style={{color: '#979797'}}>
                                        (Обслуживание физических лиц осуществляется по <br/>
                                        предварительной записи)
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="link">
                            <div>
                                <img src={locationIcon} style={{marginRight: '10px'}} alt={""} width={24}
                                     height={24}/>
                            </div>
                            <div>
                                Bonovo Road – Fomboni Island of Mohéli – Comoros Union
                            </div>
                        </div>
                        <div className="link">
                            <div>
                                <img src={call} style={{marginRight: '10px'}} alt={""} width={24} height={24}/>
                            </div>
                            <a href='tel:+353 81 888 3938' className='footer-tel-link' >
                                +353 81 888 3938
                            </a>
                        </div>
                        <div>
                            <a className="link" href="mailto:info@mezhdunarodnaya-sberkassa.com">
                                <div>
                                    <img src={mail} style={{marginRight: '10px'}} alt={""} width={24} height={24}/>
                                </div>
                                <span>
                                    info@mezhdunarodnaya-sberkassa.com
                                </span>
                            </a>
                        </div>
                        <div className="icons_block">
                            <a href="https://www.facebook.com/worldsberkassa/" target="_blank">
                                <img src={facebook} alt={""} width={28} height={28}/>
                            </a>

                            <a href="https://www.instagram.com/worldsberkassa"
                               target="_blank">
                                <img src={instagram} alt={""} width={28} height={28}/>
                            </a>

                            <a href="https://www.linkedin.com/company/%D0%BC%D0%B5%D0%B6%D0%B4%D1%83%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%B0%D1%8F-%D1%81%D0%B1%D0%B5%D1%80%D0%BA%D0%B0%D1%81%D1%81%D0%B0/"
                               target="_blank">
                                <img src={linkedin} alt={""} width={28} height={28}/>
                            </a>

                            <a href="https://wa.me/35699506970" target="_blank">
    <img src={whatsapp} alt="WhatsApp Icon" width="28" height="28" />
</a>




                            
                        </div>
                    </div>

                </div>

            </footer>
        </header>

    )
        ;
};

export default Footer;
