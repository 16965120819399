import React, {useState} from 'react';
import SideMenu from './components/SideMenu/SideMenu';
import Accounts from './components/ui/currencyAccounts/currencyAccounts';
import TransferForm from "./components/ui/BankTransferForm/transferForm";
import KycForm from "./components/ui/kycForm/kycForm";
import {useAppSelector} from "./store/store";
import CryptoWallet from "./components/ui/CryptoWallet/CryptoWallet";
import CryptoTransferForm from "./components/ui/BankTransferForm/cryptoTransferForm";
import Settings from "./components/ui/Settings/Settings";
import CurrencyExchange from "./components/ui/CurrencyExchange/CurrencyExchange";

function SideMenuLayout() {

    const [activeMenuItem, setActiveMenuItem] = useState('accounts');

    const handleMenuItemClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };


    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus);

    const kycIsValid = () => {
        return kycStatus && kycStatus !== "New" && kycStatus !== "Rejected";
    };

    const renderComponent = () => {
        if (!kycIsValid()) {
            return <KycForm />;
        }

        switch (activeMenuItem) {
            case 'accounts':
                return <Accounts />;
            case 'transfer':
                return <TransferForm />;
            case 'rate':
                return <CurrencyExchange />;
            case 'crypto':
                return <CryptoWallet />;
            case 'cryptoTransfer':
                return <CryptoTransferForm />;
            case 'kyc':
                return <KycForm />;
            case 'settings':
                return <Settings />;
            default:
                return <Accounts />;
        }
    };

    return (
        <>
            <SideMenu activeMenuItem={activeMenuItem} onItemClick={handleMenuItemClick} />
            <div className="сurrency-wrapper">
                <div className='container main'>
                    {renderComponent()}
                </div>
            </div>
        </>
    );
}

export default SideMenuLayout;
