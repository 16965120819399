import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { getAccountsListThunk, convertCurrency } from "../../../../store/reducers/actions";
import '../CurrencyExchange.css';
import CustomDropdown from "./CustomDropdown";

const ExchangeStepOne = ({ setCurrent, setExchangeData }) => {
    const dispatch = useDispatch();

    const { rate, convertedAmount, loading, error } = useSelector((state) => state.currency);
    const isLoading = useSelector((state) => state.user?.isLoading);
    const accounts = useSelector((state) => state.userReducer.accountsList);

    const [selectedFromAccount, setSelectedFromAccount] = useState(null);
    const [selectedToAccount, setSelectedToAccount] = useState(null);
    const [lastModifiedField, setLastModifiedField] = useState(null);

    const formikRef = useRef();

    useEffect(() => {
        if (!accounts || accounts.length === 0) {
            dispatch(getAccountsListThunk());
        }
    }, [dispatch, accounts]);

    const calculateConvertedAmount = (amount, direction, setFieldValue) => {
        if (selectedFromAccount && selectedToAccount && amount) {
            if (direction === "forToGet") {
                dispatch(convertCurrency(selectedFromAccount.currency, selectedToAccount.currency, parseFloat(amount)));
            } else if (direction === "getToFor") {
                dispatch(convertCurrency(selectedToAccount.currency, selectedFromAccount.currency, parseFloat(amount)));
            }
        }
    };

    useEffect(() => {
        if (selectedFromAccount && selectedToAccount) {
            dispatch(convertCurrency(selectedFromAccount.currency, selectedToAccount.currency, 1));
        }
    }, [selectedFromAccount, selectedToAccount, dispatch]);

    const handleAccountChange = (accountType, accountNumber, setFieldValue) => {
        const selectedAccount = accounts.find(acc => acc.number === accountNumber);
        if (accountType === "from") {
            setSelectedFromAccount(selectedAccount);
            setFieldValue("accNumberFrom", accountNumber);
        }
        if (accountType === "to") {
            setSelectedToAccount(selectedAccount);
            setFieldValue("accNumberTo", accountNumber);
        }
    };

    const handleForAmountChange = (e, setFieldValue) => {
        const amount = e.target.value;
        setFieldValue("forAmount", amount);
        setLastModifiedField("forAmount");
        calculateConvertedAmount(amount, "forToGet", setFieldValue);
    };

    const handleGetAmountChange = (e, setFieldValue) => {
        const amount = e.target.value;
        setFieldValue("getAmount", amount);
        setLastModifiedField("getAmount");
        calculateConvertedAmount(amount, "getToFor", setFieldValue);
    };

    useEffect(() => {
        if (formikRef.current && rate && selectedFromAccount && selectedToAccount) {
            const { setFieldValue, values } = formikRef.current;

            // Recalculate based on the last modified field whenever an account changes
            if (lastModifiedField === "forAmount" && values.forAmount) {
                calculateConvertedAmount(values.forAmount, "forToGet", setFieldValue);
            } else if (lastModifiedField === "getAmount" && values.getAmount) {
                calculateConvertedAmount(values.getAmount, "getToFor", setFieldValue);
            }

            // Update field values based on converted amount from Redux
            if (convertedAmount && lastModifiedField === "forAmount") {
                setFieldValue("getAmount", convertedAmount);
            } else if (convertedAmount && lastModifiedField === "getAmount") {
                setFieldValue("forAmount", convertedAmount);
            }
        }
    }, [selectedFromAccount, selectedToAccount, rate, lastModifiedField, convertedAmount]);

    const handleSubmit = (values) => {
        if (selectedFromAccount && selectedToAccount && values.forAmount && values.getAmount) {
            setCurrent(1);

            const exchangeData = [{
                accNumberFrom: values.accNumberFrom || selectedFromAccount.number,
                accNumberTo: values.accNumberTo || selectedToAccount.number,
                amount: values.amount || values.forAmount,
                forAmountCurrency: values.forAmountCurrency || selectedFromAccount.currency,
                toAmount: values.toAmount || values.getAmount,
                getAmountCurrency: values.getAmountCurrency || selectedToAccount.currency,
                type: values.type || 'exchange'
            }];

            setExchangeData(exchangeData);
        }
    };

    const isNextButtonDisabled = (values) => {
        return loading || !selectedFromAccount || !selectedToAccount || !values.forAmount || !values.getAmount;
    };

    if (isLoading) {
        return <p>Loading accounts...</p>;
    }

    if (error) {
        return <p>Error loading accounts: {error.message}</p>;
    }

    return (
        <div className='currency-conversion-container'>
            <div className="currency-conversion-content">
                <Formik
                    innerRef={formikRef}  // Set formikRef
                    initialValues={{
                        accNumberFrom: '',
                        accNumberTo: '',
                        amount: '',
                        forAmountCurrency: '',
                        toAmount: '',
                        getAmountCurrency: '',
                        type: 'exchange',
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, values }) => (
                        <Form className="transfer-form">
                            {rate && (
                                <div className="exchange-message">
                                    <p>Обменный курс: {rate.toFixed(3)}</p>
                                </div>
                            )}

                            <br/>
                            <div className="transfer-form-box">
                                <label htmlFor="fromAccountSelect" className='transfer-form-label'>Из аккаунта</label>
                                <CustomDropdown
                                    options={accounts}
                                    selectedValue={selectedFromAccount?.number}
                                    onChange={(e) => handleAccountChange("from", e.target.value, setFieldValue)}
                                    placeholder=""
                                />
                            </div>

                            <div className="transfer-form-box">
                                <label htmlFor="toAccountSelect" className='transfer-form-label'>На счет</label>
                                <CustomDropdown
                                    options={accounts}
                                    selectedValue={selectedToAccount?.number}
                                    onChange={(e) => handleAccountChange("to", e.target.value, setFieldValue)}
                                    placeholder=""
                                />
                            </div>

                            <div className="transfer-form-box">
                                <label htmlFor="forAmount" className='transfer-form-label'>Для</label>
                                <input
                                    type="number"
                                    name="forAmount"
                                    id="forAmount"
                                    value={values.forAmount}
                                    required
                                    className="transfer-form-input"
                                    min="0"
                                    step="0.01"
                                    onChange={(e) => handleForAmountChange(e, setFieldValue)}
                                />
                            </div>

                            <div className="transfer-form-box">
                                <label htmlFor="getAmount" className='transfer-form-label'>Получать</label>
                                <input
                                    type="number"
                                    name="getAmount"
                                    id="getAmount"
                                    value={values.getAmount}
                                    required
                                    className="transfer-form-input"
                                    min="0"
                                    step="0.01"
                                    onChange={(e) => handleGetAmountChange(e, setFieldValue)}
                                />
                            </div>

                            <div className='transfer-form-bottom'>
                                <button
                                    className='secondary-button'
                                    type="submit"
                                    disabled={isNextButtonDisabled(values)}
                                >
                                    {loading ? 'Следующий...' : 'Следующий'}
                                </button>
                            </div>
                            <br/>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ExchangeStepOne;
