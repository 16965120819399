//
import { createSlice } from '@reduxjs/toolkit';

const currencySlice = createSlice({
    name: 'currency',
    initialState: {
        currencyExchange: {
            amount: '',
            toAmount: '',
            to: '',
            from: ''
        },
        currencyExchangeCheck: {
            amount: '',
            toAmount: '',
            to: '',
            from: ''
        },
        currencyExchangeResult: null,
        currencyExchangeResultCheck: null,
        rate: null,
        convertedAmount: null,
        loading: false,
        error: null,
        
        conversionResult: null // Added for storing conversion 
    },
    reducers: {
        conversionRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        conversionSuccess: (state, action) => {
            state.loading = false;
            state.rate = action.payload.rate;
            state.convertedAmount = action.payload.amount;
            state.conversionResult = action.payload.result;
        },
        conversionFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        setAmount: (state, action) => {
            state.amount = action.payload;
        },
        setToAmount: (state, action) => {
            state.toAmount = action.payload;
        },
        setFromCurrency: (state, action) => {
            state.from = action.payload;
        },
        setToCurrency: (state, action) => {
            state.to = action.payload;
        },
        resetExchangeData: (state) => {
            state.amount = '';
            state.toAmount = '';
            state.from = '';
            state.to = '';
            state.rate = null;
            state.convertedAmount = null;
            state.conversionResult = null;
        },

    }
});

export const {
    conversionRequest,
    conversionSuccess,
    conversionFailure,
   
} = currencySlice.actions;



export default currencySlice.reducer;
