import MyDropdown from "../../../ui/CustomDropdown/MyDropdwon";
import React, { useState } from 'react';
import './RegistrationForm.css';
import MyInput from "../../../ui/MyInput/MyInput";
import { Formik } from "formik";
import MyBtn from "../../../ui/MyBtn/MyBtn";
import { postRegistration } from "../../../../store/reducers/actions";
import { useAppDispatch } from "../../../../store/store";
import CustomModal from "../../../ui/customModal/customModal";

function RegistrationForm(props) {
    const accountTypes = [
        'Индивидуальный счёт',
        'Корпоративный счёт'
    ];
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const dispatch = useAppDispatch();

    const validate = values => {
        const errors = {};

        if (!values.email) {
            errors.email = 'Email обязателен';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Некорректный email';
        }

        if (!values.phone) {
            errors.phone = 'Телефон обязателен';
        }

        if (!values.password) {
            errors.password = 'Пароль обязателен';
        } else if (values.password.length < 8) {
            errors.password = 'Пароль должен содержать минимум 8 символов';
        } else if (!/[A-Z]/.test(values.password)) {
            errors.password = 'Пароль должен содержать минимум одну заглавную букву';
        } else if (!/[a-z]/.test(values.password)) {
            errors.password = 'Пароль должен содержать минимум одну строчную букву';
        } else if (!/\d/.test(values.password)) {
            errors.password = 'Пароль должен содержать минимум одну цифру';
        }

        if (!values.accountType) {
            errors.accountType = 'Тип аккаунта обязателен';
        }

        return errors;
    };

    return (
        <>
            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!
                </div>
                <div className="modal-section">
                    {modalMessage}
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        setIsErrorPopupOpen(false);
                    }}/>
                </div>
            </CustomModal>

            <Formik
                initialValues={{
                    email: '',
                    phone: '',
                    password: '',
                    accountType: ''
                }}
                validate={validate}
                onSubmit={(values, actions) => {
                    props.setLoginData(
                        {
                            ...props.dataForSend,
                            email: values.email,
                            password: values.password,
                        }
                    );

                    const type = values.accountType === "Персональный аккаунт" ? "personal" : "business";

                    dispatch(postRegistration({ ...values, type }))
                        .then(() => {
                            console.log('Registration successful!');
                            props.setCurrent(1); // Proceed to the next step on successful registration
                        })
                        .catch(error => {
                            console.error('Registration error:', error);
                            if (error.response) {
                                const { status, data } = error.response;

                                if (status === 409) {
                                    if (data === 'Email already exist') {
                                        setModalMessage('Пользователь уже существует');
                                    } else if (data === 'Invalid password') {
                                        setModalMessage('Неверный пароль');
                                    } else {
                                        setModalMessage('Произошла ошибка, попробуйте снова');
                                    }
                                } else {
                                    setModalMessage('Ошибка сервера');
                                }
                            } else {
                                setModalMessage('Ошибка сервера');
                            }

                            setIsErrorPopupOpen(true);
                        })
                        .finally(() => {
                            actions.setSubmitting(false);
                        });
                }}
            >
                {formikProps => (
                    <form onSubmit={formikProps.handleSubmit}>
                        <div className="sign_in_content">
                            <div className="title">
                                Заявка на открытие счёта
                            </div>
                            <MyInput
                                id="email"
                                name="email"
                                placeholder="E-mail"
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur} // Added onBlur
                                required
                            />
                            {formikProps.errors.email && formikProps.touched.email && (
                                <div className="invalid-input-error">{formikProps.errors.email}</div>
                            )}

                            <MyInput
                                required
                                id="phone"
                                name="phone"
                                placeholder="Номер телефона"
                                value={formikProps.values.phone}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur} // Added onBlur
                            />
                            {formikProps.errors.phone && formikProps.touched.phone && (
                                <div className="invalid-input-error">{formikProps.errors.phone}</div>
                            )}

                            <MyInput
                                id="password"
                                required
                                name="password"
                                type="password"
                                placeholder="Пароль"
                                value={formikProps.values.password}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur} // Added onBlur
                            />
                            {formikProps.errors.password && formikProps.touched.password && (
                                <div className="invalid-input-error">{formikProps.errors.password}</div>
                            )}

                            <MyDropdown
                                customId="accountType"
                                required
                                items={accountTypes}
                                placeholder={"Тип аккаунта"}
                                id="accountType"
                                name="accountType"
                                value={formikProps.values.accountType}
                                onChange={value => formikProps.setFieldValue('accountType', value)}
                                onBlur={() => formikProps.setFieldTouched('accountType', true)} // Handle onBlur for dropdown
                            />
                            {formikProps.errors.accountType && formikProps.touched.accountType && (
                                <div className="invalid-input-error">{formikProps.errors.accountType}</div>
                            )}

                            <div className="btn_block">
                                <MyBtn
                                    title={formikProps.isSubmitting ? "Загрузка..." : "Регистрация"}
                                    type={"submit"}
                                    style={{ height: '50px', width: '200px' }}
                                    disabled={formikProps.isSubmitting}
                                />
                            </div>

                            <div className="auth-question">
                                Есть аккаунт СберКассы?
                                <a href="/login" className='auth-question-link'> Войти</a>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
}

export default RegistrationForm;
