import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {useDispatch, useSelector} from 'react-redux';
import userReducer from './reducers/UserSlice'
import transactionsReducer, { transactionsSlice} from './reducers/TransactionsSlice'
import cryptoTransactionsReducer from "./reducers/CryptoTransactionsSlice";
import currencyReducer from "./reducers/currencyExchangeReducer";

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    userReducer,
    transactionsReducer,
    cryptoTransactionsReducer,
    currency: currencyReducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
});

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export const persistor = persistStore(store);


export default store;
