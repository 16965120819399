import React from 'react';
import './Services.css';
import AutoSlider from '../slider/Slider';
import {useNavigate} from 'react-router-dom';
import MyBtn from '../ui/MyBtn/MyBtn';

function Services() {
    const navigate = useNavigate();

    const tableData = [
        {leftContent: 'Комиссия за открытие корпоративного счета: ', rightContent: 'Бесплатно'},
        {leftContent: 'Комиссия за открытие индивидуального счета:', rightContent: 'Бесплатно'},
        {leftContent: 'SWIFT, SEPA Корпоративный Расчетный счет:', rightContent: <div className='muted'>Сумма минимального депозита: 10,000 EUR</div>},
        {leftContent: 'SWIFT, SEPA Корпоративный Пул-счет:', rightContent: <div className='muted'>Сумма минимального депозита: 5,000 EUR</div>},
        {leftContent: 'SWIFT, SEPA  Персональный счет', rightContent: <div className='muted'>Сумма минимального депозита: 5,000 EUR</div>},
        {leftContent: 'SWIFT, SEPA Индивидуальный Пул-счет:', rightContent: <div className='muted'>Сумма минимального депозита: 3,000 EUR</div>},

        {leftContent: (
            <>
                Отĸрытие Депозитного Счета: <br/>
                <div className="muted"></div>
            </>), 
            rightContent: (
                <>
                    Бесплатно  <br/>
                </>)
        },
        {leftContent: 'Минимальная сумма депозита:', rightContent: <div className='muted'>10,000 EUR</div>},
        {leftContent: 'Проверка Комплайнс:', rightContent: 'Бесплатно'},

        {
            leftContent: 'Обслуживание активного счета:', rightContent: (
                <>
                    30 EUR <br/>
                    <div className="muted">Ежемесячно</div>
                </>)
        },
        {leftContent: 'Обслуживание интернет-банкинга:', rightContent: 'Бесплатно'},
        {
            leftContent: 'Обслуживание клиента повышенного риска:',
            rightContent: (
                <>
                    Cтавку назначает Банк <br/>
                    в зависимости от уровня риска клиента
                </>
            )
        },
        {leftContent: 'Индивидуальное ведение клиента:', rightContent: 'Входит в стоимость открытия счета'},
        {
            leftContent: (
                <>
                    Закрытие мультивалютного расчётного счета клиенту, <br/>
                    не соответствующему Клиентской политике Банка:
                </>
            ),
            rightContent: '100 EUR'
        },
        {leftContent: 'Предварительная проверка документов/комплайнс:', rightContent: 'Бесплатно'},
        {leftContent: 'Внутрибанковские перечисления (все валюты):', rightContent: 'Бесплатно'},
        {leftContent: 'Расследование и ручная обработка входящего платежа:', rightContent: '50 EUR'},
        {leftContent: 'Отзыв или внесение изменений в платёжное распоряжение:', rightContent: '30 EUR'},
        {leftContent: 'Конверсионные операции в валюте: ', rightContent: 'Бесплатно'},
        {leftContent: 'Конверсионные операции в криптовалюте:', rightContent: 'Требуется согласование'},
        {leftContent: 'Международный перевод, входящий/исходящий:', rightContent: (
                <>
                    0.30% <br/>
                    <div className="muted">(Мин. 50 EUR, Макс. 2000 EUR)</div>
                </>
            )
        },
        {leftContent: 'Выпуск виртуальной карты:', rightContent: '300 EUR'},
        {leftContent: 'Выпуск физической карты:', rightContent: '400 EUR'},
        {leftContent: 'Предоплаченный банковский чек:', rightContent: '100  EUR'},
       

    ];

    return (
        <section className='section'>
            <div className="service-wrapper">
                <div className='service-container container'>
                    <div className="corporate-text-content ">
                        <div className="corporate-title-block ">
                            <div className="corporate-text-content">
                                <div className="service-title">Услуги на все случаи жизни</div>
                            </div>
                        </div>

                        <AutoSlider/>
                        
                        <div className="btn-teriff-block">
                            <MyBtn
                                title="Другие Услуги" large
                                onClick={() => navigate("/other")}
                            />
                        </div>

                        <div className="section-title">Выгодные Тарифы</div>
                        <br/>

                            <div className="static-table">
                                <div className="table-row table-header">
                                    <div className="table-cell">Наименование</div>
                                    <div className="table-cell">Сумма</div>
                                </div>
                                {tableData.map((rowData, index) => (
                                    <div className="table-row" key={index}>
                                        <div className="table-cell cellText">{rowData.leftContent}</div>
                                        <div className="table-cell cellText">{rowData.rightContent}</div>
                                    </div>
                                ))}
                            </div>

                        {/* Table End */}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
