import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import './bankTransfer.css';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {SendTransferThunk} from "../../../store/reducers/actions";
import {setDisabled} from "../../../store/reducers/UserSlice";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";
import Dropdown from "../Dropdown/Dropdown";

const BankTransferForm = () => {
    const dispatch = useAppDispatch()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    const {isLoading} = useAppSelector(store => store.userReducer)
    const userAccounts = useAppSelector(store => store.userReducer.accountsList)
    const [minDate, setMinDate] = useState('');


    const handleOkClick = () => {
        setIsPopupOpen(false);
        window.location.href = '/kabinet';
    };

    useEffect(() => {
        const today = new Date();
        const minDateValue = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        setMinDate(minDateValue);
    }, []);


    const currencies = [
        'USD',
        'EUR',
        'GBP',
        'CHF',
        'RUB',
        'TRY',
        'AED',
        'CNY',
        'AUD',
        'CZK',
        'PLN',
        'CAD',
        'USDT'
    ];

    const paymentRegions = [
        "SWIFT (Международный Перевод)",
        "SEPA",
        "ACH",
        "BACS",
        "INTERAC",
        "EFT",
        "PAN AFRICA",
    ]

    return (

        <div>
            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Успешно!
                </div>
                <div className="modal-section">
                    Ваша заявка на перевод была отправлена.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        handleOkClick()
                    }}/>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!
                </div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        setIsErrorPopupOpen(false)
                    }}/>
                </div>
            </CustomModal>

            <h2 className='section-subtitle ' >Банковский Перевод</h2>
            <br/>
            <div className="bank-transfer-main-container">
                <Formik
                    initialValues={{
                        paymentMethod: '',
                        paymentRegions: '',
                        fromAccount: '',
                        fullName: '',
                        recipientAddress: '',
                        recipientsAccountNumber: '',
                        recipientsSwiftBic: '',
                        recipientCountry: '',
                        recipientsBankCountry: '',
                        recipientsBankAddress: '',
                        recipientsBank: '',
                        email: '',
                        transferDate: minDate,
                        amount: '',
                        currency: 'USD',
                        description: '',
                        templateName: '',
                        type: 'international',
                    }}
                    onSubmit={(values, actions) => {
                        dispatch(setDisabled(true))
                        const recipientDetails = {
                            fullName: values.fullName,
                            recipientsSwiftBic: values.recipientsSwiftBic,
                            recipientsBankCountry: values.recipientsBankCountry,
                            recipientsBankAddress: values.recipientsBankAddress,
                            recipientsBank: values.recipientsBank,
                            recipientsIban: values.recipientsIban,
                            recipientsAccountNumber: values.recipientsAccountNumber,
                            recipientsAddress: values.recipientsAddress,
                            country: values.recipientCountry?.value,
                            phoneNumber: values.phoneNumber,
                            recipientsEmail: values.email,
                            recipientType: values.recipientType,
                        };

                        const data = {
                            paymentRegions: values.paymentRegions,
                            fromAccount: values.fromAccount,
                            paymentDate: values.paymentDate,
                            reference: values.reference,
                            amount: Number(values.amount),
                            type: values.type,
                            purpose: values.purpose,
                            templateName: values.templateName,
                            recipient: values.bankAccount || values.phoneNumber || values.recipientsIban || values.recipientsAccountNumber || values.pixKeyValue,
                            recipientDetails: recipientDetails
                        };

                        dispatch(SendTransferThunk(userToken, data))
                            .then((res) => {
                                if (res.data === 'Created') {
                                    console.log('created')
                                    setIsPopupOpen(true)
                                } else {
                                    console.log('error')
                                    setIsErrorPopupOpen(true)
                                }
                            })
                            .catch((e) => {
                                console.log('error')
                                setIsErrorPopupOpen(true)
                            })
                            .finally(() => {
                                localStorage.removeItem("transfer-form")
                                dispatch(setDisabled(false))
                            })

                    }}
                >
                    <Form className="transfer-form">

                        <div className='transfer-form-group'>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span> Счет:
                                </label>
                                <Field name="fromAccount">
                                    {({ field, form }) => (
                                        <Dropdown
                                            field={field}
                                            form={form}
                                            options={userAccounts.map(account => account.number)}
                                            placeholder=""
                                        />
                                    )}
                                </Field>
                            </div>



                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span> регион оплаты:
                                </label>
                                <Field name="paymentRegions">
                                    {({ field, form }) => (
                                        <Dropdown
                                            field={field}
                                            form={form}
                                            options={paymentRegions}
                                            placeholder=""
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>

                        <h3 className='transfer-form-subtitle'>Детали Получателя</h3>
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Получатель
                            </label>
                            <Field type="text"
                                   name="fullName"
                                   id="fullName"
                                   placeholder=""
                                   required
                                   className="transfer-form-input"
                            />
                        </div>
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Адресс Получателя:
                            </label>
                            <Field type="text"
                                   name="recipientsAddress"
                                   id="recipientsAddress"
                                   placeholder=""
                                   required
                                   className="transfer-form-input"
                            />
                        </div>

                        <div className='transfer-form-group'>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>IBAN / Номер Счета:
                                </label>
                                <Field type="text"
                                       name="recipientsAccountNumber"
                                       id="recipientsAccountNumber"
                                       placeholder=""
                                       required
                                       className="transfer-form-input"
                                />
                            </div>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>SWIFT/BIC код:
                                </label>
                                <Field type="text"
                                       name="recipientsSwiftBic"
                                       id="recipientsSwiftBic"
                                       placeholder=""
                                       required
                                       className="transfer-form-input"

                                />
                            </div>
                        </div>
                        <div className='transfer-form-group'>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>Страна Получателя:
                                </label>
                                <Field type="text"
                                       name="recipientsBankCountry"
                                       id="recipientsBankCountry"
                                       placeholder=""
                                       required
                                       className="transfer-form-input"
                                />
                            </div>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span> Банк Получателя:
                                </label>
                                <Field type="text" 
                                       name="recipientsBank" 
                                       id="recipientsBank" 
                                       placeholder="" 
                                       required
                                       className="transfer-form-input"
                                />
                            </div>
                        </div>
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span>Адресс Банка Получателя:
                            </label>
                            <Field type="text"
                                   name="recipientsBankAddress"
                                   id="recipientsBankAddress"
                                   placeholder="" required
                                   className="transfer-form-input"
                            />
                        </div>

                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span>Электронная Почта:
                            </label>
                            <Field type="email" name="email" id="email" placeholder="" required
                                   className="transfer-form-input"
                            />
                        </div>

                        {/*    <div className='field-wrapper'>
                <label htmlFor="paymentDate"><span className="required">* </span> Дата Перевода: </label>
                <Field type="date" name="paymentDate" id="paymentDate" placeholder="" min={minDate}  required />
              </div>*/}

                        <h3 className='transfer-form-subtitle'>Детали перевода</h3>
                        <div className='transfer-form-group'>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span> Сумма:
                                </label>
                                <Field type="text" name="amount" id="amount" placeholder="" required
                                       className="transfer-form-input"
                                />
                            </div>

                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span> Валюта:
                                </label>
                                <Field name="transferCurrency">
                                    {({ field, form }) => (
                                        <Dropdown
                                            field={field}
                                            form={form}
                                            options={currencies}
                                            placeholder=""
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span>Причина:
                            </label>
                            <Field as="textarea"
                                   name="reference"
                                   id="reference"
                                   placeholder="Введите причину перевода"
                                   rows="4"
                                   className='transfer-form-textarea'
                            />
                        </div>

                        {/*        <div className='translation-details-container'>
            <h2>Сохранить детали перевода как шаблон</h2>
            <div className="field-wrapper">
              <label htmlFor="templateName">Наименование Шаблона: </label>
              <Field type="text" name="templateName" id="templateName" placeholder="" />
            </div>
          </div>
          */}
                        <div className='transfer-form-bottom'>
                            <label className='transfer-form-label'>
                            <span className="transfer-form-icon">* </span> Обязательные поля
                            </label>
                            <button className='secondary-button' type="submit">Подтвердить</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>


    );
};

export default BankTransferForm;
