// ConvertStepTwo.js
import React, { useState } from 'react';
import MyBtn from "../../MyBtn/MyBtn";
import CustomModal from "../../customModal/customModal";
import { SendTransferThunk } from '../../../../store/reducers/actions';
import { useAppDispatch, useAppSelector } from "../../../../store/store";

const ConvertStepTwo = ({ setCurrent, exchangeData }) => {
    const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const token = useAppSelector(state => state.userReducer.user.userToken);

    const sendExchangeData = () => {
        if (!exchangeData || exchangeData.length === 0 || !exchangeData[0].amount) {
            setIsErrorPopupOpen(true);
            return;
        }

        setIsLoading(true);
        const tempData = { ...exchangeData[0], amount: parseFloat(exchangeData[0].amount) };

        dispatch(SendTransferThunk(token, tempData))
            .then((res) => {
                setIsLoading(false);
                if (res?.data === 'Created') {
                    setIsSuccessPopupOpen(true);
                } else {
                    setIsErrorPopupOpen(true);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setIsErrorPopupOpen(true);
            });
    };

    return (
        <>
            {/* Success Modal */}
            <CustomModal isModalOpen={isSuccessPopupOpen} setIsModalOpen={setIsSuccessPopupOpen}>
                <p className="modal-section">
                    Успех! Ваш запрос отправлен.
                </p>
                <div className="modal-section">
                    <MyBtn title={'Ok'} large onClick={() => setCurrent(0)}/>
                </div>
            </CustomModal>

            {/* Error Modal */}
            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <p className="modal-section">
                    Упс! Что-то пошло не так. Пожалуйста, попробуйте еще раз.
                </p>
                <div className="modal-section">
                    <MyBtn title={'Ok'} large onClick={() => setIsErrorPopupOpen(false)}/>
                </div>

            </CustomModal>
            
            <div className="currency-conversion-content">
                <h2>Резюме обмена</h2>
                <br/>
                <table>
                    <thead>
                    <tr>
                        <th scope="col">Со счета</th>
                        <th>На счет</th>
                        <th>Продать</th>
                        <th>Купить</th>
                    </tr>
                    </thead>
                    <tbody>
                    {exchangeData?.map((row, index) => (
                        <tr key={index}>
                            <td data-label="Со счета">{row.accNumberFrom}</td>
                            <td data-label="На счет">{row.accNumberTo}</td>
                            <td data-label="Продать">{row.amount} {row.forAmountCurrency}</td>
                            <td data-label="Купить">{row.toAmount} {row.getAmountCurrency}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <div style={{display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: '20px'}}>
                    <MyBtn title={'Back'} medium onClick={() => setCurrent(0)}/>
                    <MyBtn title={'Confirm'} medium onClick={sendExchangeData} disabled={isLoading}/>
                </div>
                <br/>
            </div>

        </>
    );
};

export default ConvertStepTwo;
